.main {
  margin: 0;
  padding: 0;
  /* font-family: "Arial Narrow", Arial, sans-serif !important; */
  font-family: 'Montserrat', sans-serif !important;
}
.hero {
  background-image: url("./../assets/main2.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 5% -100px;
  /* background-position: left -100px ; */
  height: 100vh;
  width: 100vw;
}

/* Intro screen */
.intro {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #161616;
  transition: 1s;
}

.logo-header {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
  color: #B4853F;
}

.logo2 {
  position: relative;
  display: inline-block;
  bottom: -20px;
  opacity: 0;

}

.logo2.active {
  bottom: 0;
  opacity: 1;
  transition: ease-in-out 0.5s;
}


@media (min-width: 768px) {
.hero {
    background-image: url("./../assets/main2.webp");
    background-repeat: no-repeat;
    background-size: cover;
    
    height: 100vh;
    width: 100vw;
}
}

/* .title {
  color: #000;
  text-align: center;
  font-family: Suranna;
  font-size: 250px;
  font-style: normal;
  font-weight: 400;
  line-height: 100px; /* 40% */
  /* letter-spacing: 57.5px;
  text-transform: uppercase;
  
}

.titleSub{
  text-align: center;
  font-family: Suranna;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 100px; 208.333% 
  letter-spacing: 11.04px;
  text-transform: uppercase; 
  padding: 1em;
} 



*/

.door-cover{
  /* background-image: url("./../assets/door1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center ;
  height: 30vh; */
  
}

.testimonials-carousel .carousel .slide img {
width: 139px !important;
border-radius: 50%;
}

.testimonials-carousel .carousel-root {
position: relative;
width: 64% !important;
margin: auto !important;
margin-top: 3% !important;
}

.testimonials-carousel .carousel .slide {
background: #fff !important;
color: black;
height: 100%;
}

.testimonials-carousel .carousel .control-dots {
padding-left: 5px !important;
outline: 0;
}

.testimonials-carousel .myCarousel {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: #fafafa;
margin-top: -6%;
width: 54%;
margin-left: auto;
margin-right: auto;
padding: 6% 5% 8%;
border: 1px solid #ddd;
height: 560px;
}

.testimonials-carousel .myCarousel h3 {
color: #222;
font-weight: 600;
text-transform: uppercase;
font-size: 17px;
margin: 0;
}

.testimonials-carousel .myCarousel h4 {
text-transform: uppercase;
margin: 0;
font-weight: 500;
color: #787878;
font-size: 14px;
}

.testimonials-carousel .myCarousel p {
font-weight: 100 !important;
line-height: 29px !important;
color: #222;
font-size: 15px;
font-family: sans-serif;
max-height: none;
margin: 0;
}

.testimonials-carousel .myCarousel p:before {
content: "“";
color: #aaa;
font-size: 26px;
font-family: monospace;
font-weight: 100;
}

.testimonials-carousel .myCarousel p:after {
content: "”";
color: #aaa;
font-size: 26px;
font-family: monospace;
font-weight: 100;
line-height: 0;
}

.testimonials-carousel .carousel .control-dots .dot {
box-shadow: none !important;
background: #454545 !important;
outline: 0;
}

.testimonials-carousel .carousel.carousel-slider .control-arrow {
background: #000 !important;
height: 50px !important;
position: absolute;
top: 50% !important;
transform: translateY(-50%);
z-index: 2;
}

.testimonials-carousel .carousel.carousel-slider .control-prev.control-arrow {
left: 10px !important;
}

.testimonials-carousel .carousel.carousel-slider .control-next.control-arrow {
right: 10px !important;
}

@media only screen and (max-width: 934px) {
.testimonials-carousel .carousel-root {
  outline: 0;
  width: 93% !important;
  margin: auto !important;
  position: relative; /* Ensure position relative in media query */
}

.testimonials-carousel .carousel.carousel-slider .control-arrow {
  display: none !important;
}

.testimonials-carousel .myCarousel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  margin-top: -6%;
  width: 88%;
  margin-left: auto;
  margin-right: auto;
  padding: 6% 5% 8%;
  border: 1px solid #ddd;
  height: 650px;
}

.testimonials-carousel .carousel .slide img {
  width: 24% !important;
  border-radius: 50%;
}
}

.projects-carousel .carousel .slide img {
width: 100% !important;
height: 100% !important;
border-radius: 0; /* Ensure images are not rounded */
object-fit: cover; /* Ensure images cover the container */
}

.projects-carousel .carousel-root {
position: relative;
width: 100% !important;
margin: auto !important;
}

.projects-carousel .carousel .slide {
background: #fff !important;
color: black;
height: 100%;
}

.projects-carousel .carousel .control-dots {
padding-left: 5px !important;
outline: 0;
}

.projects-carousel .carousel .control-dots .dot {
box-shadow: none !important;
background: #454545 !important;
outline: 0;
}

.projects-carousel .carousel.carousel-slider .control-arrow {
background: #000 !important;
height: 50px !important;
position: absolute;
top: 50% !important;
transform: translateY(-50%);
z-index: 2;
}

.projects-carousel .carousel.carousel-slider .control-prev.control-arrow {
left: 10px !important;
}

.projects-carousel .carousel.carousel-slider .control-next.control-arrow {
right: 10px !important;
}